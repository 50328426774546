import Collapse from "~bsscript/collapse";
import ScrollBarHelper from "~bsscript/util/scrollbar";
import AnimateDisplay from "../repos/AnimateDisplay/src/AnimateDisplay";

class navResonsiveCollapse {
    constructor() {
        this.navCollapse = document.querySelector('#navigation');
        this.navToggle = document.querySelector('#nav-toggle');
        this.navTop = document.querySelector('#nav-top');
        // this.navTopAnimated = {};
        this.backdrop = document.querySelector('.backdrop');
        this.bsNavCollapse = null;
        if (!this.navCollapse || !this.navToggle || !this.navTop) {
            console.log('%cnavResponsiveCollapse Error: %cthe required DOM elements do not exist', 'color:red;font-weight:bold', 'color:red;');
        }

        this.backdropAnimate = new AnimateDisplay({
            target: this.backdrop,
            displayType: "block",
            animationClass: "show"
        });

        this.checkViewPortSize();
        this.addListeners();
        this.lock = true;
        this.scrollBar = new ScrollBarHelper()
    }

    addListeners() {
        this.navToggle.addEventListener('click', event => {
            this.bsNavCollapse.toggle();
        });
        this.navCollapse.addEventListener('show.bs.collapse', event => {
            // differentiation in collapse-events (source/target)
            // console.log((event.target == this.bsNavCollapse._element ? true : false));
            if (this.bsNavCollapse && (event.target == this.bsNavCollapse._element)) {
                this.navToggle.classList.add('active');
                let toggleBottom = this.navToggle.getBoundingClientRect().bottom;
                console.log("Navigation-Top-Position: " + toggleBottom);
                console.log(this.navCollapse.querySelector("ul"));
                this.navCollapse.style.maxHeight = "calc(100vh - " + toggleBottom + "px)";
                this.navCollapse.querySelector(`:scope > ul`).style.maxHeight = "calc(100vh - " + toggleBottom + "px)";
                this.scrollBar.hide();
                this.backdropAnimate.show();
            }
        });
        this.navCollapse.addEventListener('hide.bs.collapse', event => {
            // differentiation in collapse-events (source/target)
            // console.log((event.target == this.bsNavCollapse._element ? true : false));
            if (this.bsNavCollapse && (event.target == this.bsNavCollapse._element)) {
                this.navToggle.classList.remove('active');
                this.backdropAnimate.hide();
            }
        });
        this.navCollapse.addEventListener('shown.bs.collapse', event => {
            // differentiation in collapse-events (source/target)
            // console.log((event.target == this.bsNavCollapse._element ? true : false));
            if (this.bsNavCollapse && (event.target == this.bsNavCollapse._element)) {
                this.navToggle.classList.add('open');
            }
        });
        this.navCollapse.addEventListener('hidden.bs.collapse', event => {
            // differentiation in collapse-events (source/target)
            //console.log((event.target == this.bsNavCollapse._element ? true : false));
            if (this.bsNavCollapse && (event.target == this.bsNavCollapse._element)) {
                this.navToggle.classList.remove('open');
                this.scrollBar.reset();
                // hide collapse-subItems of navigation
                let subItems = document.querySelectorAll("#navigation .level_1.collapse");
                subItems?.forEach((subitem) => {
                    Collapse.getInstance(subitem)?.hide();
                    console.log(`Sub-Navigation ${subitem.id} hidden`);
                });
            }
        });
        window.addEventListener('scrolled-down', event => {
            this.hideCollapse();
            let subItems = document.querySelectorAll("#navigation .level_1.collapse");
            subItems?.forEach((subitem) => {
                Collapse.getInstance(subitem)?.hide();
                console.log(`Sub-Navigation ${subitem.id} hidden`);
            });
        });
        window.addEventListener('scrolled-up', event => {
            this.hideCollapse();
            // this.navTopAnimated.show();
        });
    }

    createCollapse() {
        if (this.navCollapse) {
            this.navCollapse.classList.add('collapse');
            this.navTop.classList.add('is-fixed');
            if (!(this.bsNavCollapse instanceof Collapse)) {
                this.bsNavCollapse = new Collapse(this.navCollapse, {
                    toggle: false
                });
                console.log("Navigation-Collapse created");
            }
        }
    }
    
    removeCollapse() {
        if (this.navCollapse && this.navToggle) {
            this.navTop.classList.remove('is-fixed');
            this.navCollapse.classList.remove('collapse');
            this.navCollapse.classList.remove('show');
            this.navToggle.classList.remove('open');
            this.navToggle.classList.remove('active');
            if (this.bsNavCollapse instanceof Collapse) {
                this.bsNavCollapse.dispose();
                this.bsNavCollapse = null;
                console.log("Navigation-Collapse removed");
            }
        }
    }

    hideCollapse() {

        if (this.navCollapse && this.navToggle) {
            if (this.bsNavCollapse) {
                this.bsNavCollapse.hide();
                console.log("Navigation-Collapse hidden");
            }
            //this.navCollapse.classList.remove('show');
            this.navToggle.classList.remove('open');
            this.navToggle.classList.remove('active');
        }

    }

    checkViewPortSize(breakpoint = 768) {
        if (window.innerWidth < breakpoint) {
            this.createCollapse();
            // if(!(this.navTopAnimated instanceof AnimateDisplay)) {
            //     this.navTopAnimated = new AnimateDisplay({ target: this.navTop, animationClass: "hide" });
            //     console.log("Type of navTopAnimated: ", (this.navTopAnimated instanceof AnimateDisplay));
            // }
        } else {
            this.removeCollapse();
            // this.navTopAnimated = {};
        }
    }

    scrollLock() {
        if (this.lock) {
        }
    }

}

export default navResonsiveCollapse
