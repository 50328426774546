import Swiper, { Navigation, Pagination, Scrollbar, Keyboard } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './carousel.scss';

document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".swiper", {
      modules: [Navigation, Pagination, Scrollbar, Keyboard],
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });
  });