import './herobanner.scss';


window.addEventListener('load', function() {
    let nav = document.querySelector('#nav-top').offsetHeight;
    let header = document.querySelector('#header').offsetHeight;
    let herobanner = document.querySelectorAll('.herobanner');
    
    const heroHeight = () => {
        let bottom = 50;
        let headerHeight = ((window.innerWidth < 768 ? header + bottom : header + nav + bottom));
        [...herobanner].map(herobanner => herobanner.style.height = 'calc( 100vh - '+headerHeight+'px )');
    }
    heroHeight();
    window.addEventListener("resize", function () {
        heroHeight();
    })
});