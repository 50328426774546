//import 'vite/modulepreload-polyfill'
//import { createApp } from 'vue'
//import './style.css'
//import './scss/floerchinger.scss'
import './header/header.scss'
import './fonts/titillium.scss'
//import './navigation-top/navigation-top.scss'
import './login/login.scss'
import './main/main.scss'
import './pagebanner/pagebanner.scss'
import './navigation-top/navigation-top'
import './navigation-footer/navigation-footer'
import './blocks/carousel/carousel'
import './herobanner/herobanner';

import './blocks/default.scss'
import './blocks/columns-extended/fl-columns.scss'
import './blocks/solution/solution.scss'
import './blocks/banner/banner.scss'
import './blocks/icontext/icontext.scss'
import './blocks/action/actionblock.scss'
import './blocks/anchor/anchor.scss'
import './blocks/gallery/image-gallery.scss'

//import App from './App.vue'
//createApp(App).mount('#app')

console.log("Environment Production:",import.meta.env.PROD);
console.log("Environment Mode:",import.meta.env.MODE);

//Disable console.log/.error/.warn while mode production
if (import.meta.env.MODE == "production") {

    let disFunc = () => 'console has been disabled in production mode';

    console.log = disFunc
    console.error = disFunc
    console.warn = disFunc

    Object.freeze(console);

}