class AnimateDisplay {
    constructor({ target, animationClass, displayType = "block", startStyles = null, endStyles = null }) {
        this.target = target;
        this.animationClass = animationClass;
        this.displayType = displayType;
        this.startStyles = startStyles;
        this.endStyles = endStyles;
        this.existingStyles = false;
        this.startProperties = {};
        this.startStyleProperties = {};
        this.transitionProperties = {};
        this.state = null;
        this.timeout = null;

        // if ((this.styles && this.state == "hidden")) {
        //     this.existingStyles = Object.assign({}, this.target.style);
        // }
        // Disable console.log/.error/.warn while mode production
        // if (import.meta.env.MODE == "production") {

        //     let disFunc = () => 'console has been disabled in production mode';

        //     console.log = disFunc
        //     console.error = disFunc
        //     console.warn = disFunc

        //     Object.freeze(console);

        // }

        // this ensures that initialization does not occur until the DOM has been fully loaded
        if (document.readyState != "complete") {
            document.addEventListener("readystatechange", () => {
                if (document.readyState == "complete") {
                    console.log("AnimateDisplay | readyState: " + document.readyState);
                    this.init();
                }
            });
        } else {
            this.init();
        };

        this.listener();


    }

    // Reset to initial status of target element
    init() {

        if (!this.timeout) {
            this.timeout = getComputedStyle(this.target).transitionDuration.split(",");
            this.timeout = this.timeout.map((duration) => { return this.#durationToTime(duration) + 20; });
            this.timeout.sort((a, b) => (a > b ? -1 : 0));
        }

        this.target.classList.remove(this.animationClass); // remove transition-class
        let startStyle = getComputedStyle(this.target);
        this.existingStyles = Object.assign({}, this.target.style);
        if (startStyle.display == "block" || startStyle.display == "inline-block" || startStyle.display == "flex") {
            this.state = "shown";
            this.displayType = startStyle.display;
        } else {
            this.state = "hidden";
        }
        this.showState();
    }
    
    reset() {
        if(this.state == "hide" || this.state == "hidden") {
            this.init();
        }
        this.target.style.display = null;
    }

    listener() {
        this.target.addEventListener('transitionrun', (event) => {
            console.log("transitionrun | state: " + this.state + ", property: " + event.propertyName);
            this.transitionProperties[event.propertyName] = this.startProperties[event.propertyName];
        });
        // if class doesn't contain the animationClass then adding style "display: none" after css-transition 
        this.target.addEventListener('transitionend', () => {
            //console.log("transition-start: " + this.state);
            switch (this.state) {
                case "show":
                    this.state = "shown";
                    //this.target.classList.replace("transitioning","show");
                    this.target.dispatchEvent(new Event('shown'));
                    //console.log("transition-end: " + this.state);
                    break;
                case "hide":
                    this.state = "hidden";
                    this.target.style.display = "none";
                    //this.removeStyles();
                    this.target.dispatchEvent(new Event('hidden'));
                    //console.log("transition-end: " + this.state);
                    break;
                case "shown":
                    //console.log("transition-end: " + this.state);
                    break;
                case "hidden":
                    //console.log("transition-end: " + this.state);
                    break;
                default:
                    break;
            }
        });
    }


    toggle(event) {
        console.log("toggle-start | state: " + this.state);
        switch (this.state) {
            case "shown":
                this.state = "hide";

                // get a copy of all computed styles
                this.startProperties = Object.assign({}, getComputedStyle(this.target));

                // get defined inline-styles
                for (let i = 0; i < this.target.style.length; i++) {
                    let key = this.target.style.item(i);
                    this.startStyleProperties[key] = this.target.style[key];
                }

                // toggle the transition-class
                this.target.classList.toggle(this.animationClass);

                this.target.dispatchEvent(new Event(this.state));

                setTimeout(() => { // failsafe: if transition didn't start
                    this.state = "hidden";
                    this.target.style.display = "none";
                    //this.target.dispatchEvent(new Event(this.state));
                    console.log("toggle-end | state: " + this.state);
                }, this.timeout[0])

                break;
            case "hidden":
                this.state = "show";
                this.target.dispatchEvent(new Event(this.state));

                this.startProperties = Object.assign({}, getComputedStyle(this.target));
                this.target.style.display = this.displayType;
                this.target.style.height = getComputedStyle(this.target).height;
                void this.target.offsetWidth; // force triggering a DOM "reflow"
                this.target.style.height = null;

                this.target.classList.toggle(this.animationClass);

                setTimeout(() => { // failsafe: if transition didn't start
                    this.state = "shown";
                    this.target.style.display = this.displayType;
                    //this.target.dispatchEvent(new Event(this.state));
                    console.log("toggle-end | state: " + this.state);
                }, this.timeout[0])

                break;
            default:
                event.stopPropagation();
                break;
        }

    }

    hide() {
        if (this.state == "shown") {
            this.state = "hide";

            // get a copy of all computed styles
            this.startProperties = Object.assign({}, getComputedStyle(this.target));

            // get defined inline-styles
            for (let i = 0; i < this.target.style.length; i++) {
                let key = this.target.style.item(i);
                this.startStyleProperties[key] = this.target.style[key];
            }

            // toggle the transition-class
            this.target.classList.toggle(this.animationClass);

            this.target.dispatchEvent(new Event(this.state));

            setTimeout(() => { // failsafe: if transition didn't start
                if(this.state == "hide") { 
                    this.state = "hidden";
                    this.target.style.display = "none";
                    this.target.dispatchEvent(new Event(this.state));
                    console.log("hide-end | state: " + this.state, "hide-duration: "+this.timeout[0]);
                }
            }, this.timeout[0])
        }
    }

    show() {
        if (this.state == "hidden") {
            this.state = "show";
            this.target.dispatchEvent(new Event(this.state));

            this.startProperties = Object.assign({}, getComputedStyle(this.target));
            this.target.style.display = this.displayType;
            this.target.style.height = getComputedStyle(this.target).height;
            void this.target.offsetWidth; // force triggering a DOM "reflow"
            this.target.style.height = null;

            this.target.classList.toggle(this.animationClass);

            setTimeout(() => { // failsafe: if transition didn't start
                if(this.state == "show") {
                    this.state = "shown";
                    this.target.style.display = this.displayType;
                    this.target.dispatchEvent(new Event(this.state));
                    console.log("toggle-end | state: " + this.state);
                }
            }, this.timeout[0])
        }
    }

    showState() {
        console.log("%c--------------------------------------", "color: #999999;");
        console.log("%cAnimateDisplay.js", "margin-bottom: .5em; font-weight: bold; color: red;");
        console.log("%cTarget DOM-Element:", "font-weight: bold;", this.target);
        console.log("%cAnimationClass:", "font-weight: bold;", this.animationClass);
        console.log("%cDisplayType:", "font-weight: bold;", this.displayType);
        console.log("%cCurrent state:", "font-weight: bold;", this.state);
        console.log("%cDuration:", "font-weight: bold;", this.timeout);
        console.log("%c--------------------------------------", "color: #999999;");
    }

    // private
    #removeStyles() {
        if (this.styles) {
            for (let [key, value] of Object.entries(this.styles)) {
                this.target.style[key] = (this.existingStyles != null ? this.existingStyles[key] : null);
            }
        }
    }

    // private
    #addStyles() {
        if (this.styles) {
            for (let [key, value] of Object.entries(this.styles)) {
                this.target.style[key] = (typeof value == "function" ? value() : value).toString();
            }
        }
    }

    // private
    #durationToTime(strTime) {
        let res = 0;
        let split = strTime.match(/[a-zA-Z]+|[.0-9]+/g);

        // conversion to milliseconds
        if (split[1] == "s") {
            res = parseFloat(split[0]) * 1000;
        } else {
            res = parseFloat(split[0]);
        }

        return res;
    }

    // private
    #getTransitionDuration(element) {
        let style = getComputedStyle(element);
        return this.#durationToTime(style.transitionDuration);
    }
};


export default AnimateDisplay;