// Styles
import './styles/navigation-top.scss';
import './styles/navigation-toggle.scss';
import './styles/navigation-main.scss';
import './styles/navigation-level0.scss';
import './styles/navigation-level1.scss';
import './styles/navigation-sub.scss';
import './styles/navigation-category.scss';
import './styles/navigation-backdrop.scss';
import './styles/displaytest.scss';

// Libraries
import AnimateDisplay from './repos/AnimateDisplay/src/AnimateDisplay';
import navResonsiveCollapse from './libs/navResonsiveCollapse.js';
import navResonsiveSubCollapse from './libs/navResonsiveSubCollapse.js';
import navHideOnScroll from './libs/navHideOnScroll.js';

// Document
document.addEventListener("DOMContentLoaded", function () {
    const header = new navHideOnScroll({ elHeader: "#header", elNav: "#nav-top"});
    const navTopResp = new navResonsiveCollapse();
    //const navTopSub = new navResonsiveSubCollapse();
    window.addEventListener("scroll", function () {
        header.update();
    });
    window.addEventListener("resize", function () {
        header.setFixHeight();
        //navTopSub.updateHeight();
        navTopResp.checkViewPortSize();
    })

});

// window.addEventListener('DOMContentLoaded', (event) => {

//     let nav = document.querySelectorAll("#nav-top .collapse");

//     // Hide collapsed Subnav-Items when out of viewport
//     const observerCallback = (entries, observer) => {
//         entries.forEach((entry) => {
//             if(!entry.isIntersecting && entry.target.classList.contains("show")) {
//                 let bscol = Collapse.getInstance(entry.target);
//                 entry.target.addEventListener("hidden.bs.collapse", event => { console.log(entry.target.id + " successfully hidden"); });
//                 console.log("Out of viewport: " + entry.target.id);
//                 bscol?.hide()
//             }
//         });
//     };

//     let observer = new IntersectionObserver(observerCallback);
//     nav.forEach((collapseItem) =>{
//         observer.observe(collapseItem);
//     });

// }, false);