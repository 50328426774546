import AnimateDisplay from "../repos/AnimateDisplay/src/AnimateDisplay";
class navHideOnScroll {
  constructor(options = { elHeader: "#header", elNav: "#nav-top", breakpoint: 768 }) {
    this.scroll_last = 0;
    this.elHeader = document.querySelector(options.elHeader);
    this.elNav = document.querySelector(options.elNav);
    this.status = 'init';
    this.breakpoint = (options.breakpoint ?? 768);
    if (!this.elHeader || !this.elNav) {
      console.log('%cnavHideOnScroll Error: %cthe required DOM elements do not exist', 'color:red;font-weight:bold', 'color:red;');
    }
    this.elNavAnimated = new AnimateDisplay({ target: this.elNav, animationClass: "scrolled-down" });

    // this ensures that initialization does not occur until the DOM has been fully loaded
    if (document.readyState != "complete") {
      document.addEventListener("readystatechange", () => {
        if (document.readyState == "complete") {
          console.log("AnimateDisplay | readyState: " + document.readyState);
          this.setFixHeight();
        }
      });
    } else {
      this.setFixHeight();
    };
  }

  setFixHeight() {
    this.fixHeight = this.elHeader.offsetHeight;
    if (window.innerWidth < this.breakpoint) {
      this.fixHeight = this.elHeader.offsetHeight - this.elNav.offsetHeight;
    }
    console.log("setFixHeight: ", this.fixHeight);
  }

  update() {
    let actual = window.scrollY;
    let hide_height =
      window.innerHeight / 1 > this.elNav.offsetHeight
        ? window.innerHeight / 1
        : this.elNav.offsetHeight;
    console.log("this.fixHeight: ", this.fixHeight);
    // added is-fixed for bootstraps scrollbar-helper (padding-right)
    if (!this.elNav.classList.contains("is-fixed") && (window.innerWidth < this.breakpoint)) {
      this.elNav.classList.add("is-fixed");
    }
    if (actual > this.fixHeight) {
      this.elNav.classList.add("scroll-fix");

      // added is-fixed for bootstraps scrollbar-helper (padding-right)
      if (!this.elNav.classList.contains("is-fixed") && (window.innerWidth > this.breakpoint)) {
        this.elNav.classList.add("is-fixed");
        this.elHeader.style.marginBottom = "50px";
      }

      if (
        (actual > this.elHeader.offsetHeight + hide_height) &&
        (this.scroll_last - actual) < 1
      ) {

        if (this.status != 'scrolled-down') {
          window.dispatchEvent(new Event('scrolled-down'));
          this.status = 'scrolled-down';
          document.querySelector('#wrapper').classList.remove('scrolled-up');
          this.elNavAnimated.hide();
        }

      } else if (
        (actual > this.elHeader.offsetHeight + hide_height) &&
        (this.scroll_last - actual) > 0
      ) {

        if (this.status != 'scrolled-up') {
          this.status = 'scrolled-up';
          document.querySelector('#wrapper').classList.add('scrolled-up');
          window.dispatchEvent(new Event('scrolled-up'));
          this.elNavAnimated.show();
        }


      }
    } else {
      this.elNav.classList.remove("scroll-fix");

      // remove is-fixed for desktop-viewport-width (padding-right)
      if (this.elNav.classList.contains("is-fixed") && (window.innerWidth > this.breakpoint)) {
        this.elNav.classList.remove("is-fixed");
        document.querySelector('#wrapper').classList.remove('scrolled-up');
        this.elHeader.style.marginBottom = null;
      }

      this.status = 'init';
      this.elNavAnimated.reset();

    }
    this.scroll_last = actual;
  }

}

export default navHideOnScroll;